export const XIcon: React.FunctionComponent<{
  color?: string;
  width?: number;
  height?: number;
}> = ({ color, height, width }) => {
  return (
    <svg
      width={width ? width : '9px'}
      height={height ? height : '9px'}
      viewBox="0 0 9 9"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g id="Customer-facing" transform="translate(-140.000000, -606.000000)" stroke={'#ffffff'}>
          <g id="Group-2" transform="translate(66.000000, 586.000000)">
            <g id="Group" transform="translate(75.000000, 21.000000)">
              <line x1="0" y1="6.81835938" x2="6.51953125" y2="0" id="Path"></line>
              <line x1="0" y1="0" x2="6.51953125" y2="6.81835938" id="Path-2"></line>
            </g>
          </g>
        </g>
      </g>
      <style jsx>{`
        g {
          stroke: ${color ? color : '#fff'};
        }
      `}</style>
    </svg>
  );
};

export const MinusIcon: React.FunctionComponent<{
  color?: string;
  width?: number;
  height?: number;
}> = ({ color, width, height }) => {
  return (
    <svg
      width={width ? width : '13px'}
      height={height ? height : '13px'}
      viewBox="0 0 13 2"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 4</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
      >
        <g
          id="Customer-facing"
          transform="translate(-168.000000, -651.000000)"
          stroke={color ? color : '#000'}
          stroke-width="2"
        >
          <g id="Group-2" transform="translate(67.000000, 586.000000)">
            <g id="Group-4" transform="translate(102.000000, 61.000000)">
              <g id="Group">
                <line
                  x1="2"
                  y1="2"
                  x2="8.66552901"
                  y2="8.67236162"
                  id="Path-2"
                  transform="translate(5.332765, 5.336181) rotate(-45.000000) translate(-5.332765, -5.336181) "
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const PlusIcon: React.FunctionComponent<{
  color?: string;
  width?: number;
  height?: number;
}> = ({ color, width, height }) => {
  return (
    <svg
      width={width ? width : '13px'}
      height={height ? height : '13px'}
      viewBox="0 0 13 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 4</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g
          id="Customer-facing"
          transform="translate(-139.000000, -646.000000)"
          stroke={color ? color : `#000000`}
          strokeWidth="2"
        >
          <g id="Group-2" transform="translate(67.000000, 586.000000)">
            <g id="Group-4" transform="translate(73.000000, 61.000000)">
              <g id="Group">
                <line
                  x1="2"
                  y1="8.66603633"
                  x2="8.6718543"
                  y2="2"
                  id="Path"
                  transform="translate(5.335927, 5.333018) rotate(-45.000000) translate(-5.335927, -5.333018) "
                ></line>
                <line
                  x1="2"
                  y1="2"
                  x2="8.66552901"
                  y2="8.67236162"
                  id="Path-2"
                  transform="translate(5.332765, 5.336181) rotate(-45.000000) translate(-5.332765, -5.336181) "
                ></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const UpArrowIcon: React.FunctionComponent<{ color?: string }> = () => {
  return (
    <svg
      width="16px"
      height="8px"
      viewBox="0 0 16 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Customer-facing" transform="translate(-725.000000, -536.000000)" strokeWidth="2">
          <g id="Group-2" transform="translate(477.000000, 514.000000)">
            <g id="Group-3" transform="translate(145.000000, 0.000000)">
              <polyline
                id="Path-3"
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                transform="translate(111.000000, 25.777344) scale(-1, -1) translate(-111.000000, -25.777344) "
                points="104 23 111 28.5546875 118 23"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const DownArrowIcon: React.FunctionComponent<{
  color?: string;
}> = (props) => {
  return (
    <svg
      width="16px"
      height="8px"
      viewBox="0 0 16 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="Customer-facing"
          transform="translate(-581.000000, -535.000000)"
          stroke={props.color}
        >
          <g id="Group" transform="translate(478.000000, 513.000000)">
            <g id="Group-2">
              <polyline id="Path-3" points="104 23 111 28.5546875 118 23"></polyline>
            </g>
          </g>
        </g>
      </g>
      <style jsx>{`
        g {
          stroke: #000;
        }
      `}</style>
    </svg>
  );
};
