import React, { useMemo } from 'react';
import Downshift from 'downshift';
import { RecipeFilter, RecipeFilterOption } from '../types/api/entities/recipe-filter';
import { FilterButton } from './buttons';
import { UpArrowIcon, DownArrowIcon, PlusIcon, MinusIcon } from './Icons';
import { mediaQuery, minimumSize } from '../util/ui';
import { useMediaQuery } from 'react-responsive';

export interface FilterSelectProps {
  filter: RecipeFilter;
  isOpen: boolean;
  setOpenFilterId: (id: number | undefined) => void;
  setSelectedOptions: (selectedOptions: RecipeFilterOption[]) => void;
  selectedOptions: RecipeFilterOption[];
}

export const FilterSelect: React.FunctionComponent<FilterSelectProps> = ({
  filter,
  isOpen,
  setOpenFilterId,
  setSelectedOptions,
  selectedOptions,
}) => {
  const isMobile = useMediaQuery({ maxWidth: `${minimumSize.Medium}px` });

  const options = useMemo<RecipeFilterOption[]>(
    () => filter.options.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [filter.options],
  );

  return (
    <>
      <Downshift
        isOpen={isOpen}
        onOuterClick={() => {
          if (!isMobile) {
            setOpenFilterId(undefined);
          }
        }}
        onSelect={(newOption: RecipeFilterOption) => {
          if (selectedOptions.some((o) => o.id === newOption.id)) {
            // remove it since it's already in selected options
            setSelectedOptions(selectedOptions.filter((o) => o.id !== newOption.id));
          } else {
            // add it since it's new
            setSelectedOptions([...selectedOptions, newOption]);
          }
        }}
        itemToString={(item) => (item ? item.value : '')}
      >
        {({ getItemProps, getMenuProps, isOpen, highlightedIndex }) => {
          const lis = options.map((item, index) => {
            const itemSelected = selectedOptions.some((o) => o.id === item.id);
            return (
              <li
                key={item.slug}
                {...getItemProps({
                  key: item.slug,
                  index,
                  item,
                  style: {
                    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                    fontWeight: itemSelected ? 'bold' : 'normal',
                  },
                })}
              >
                <div className="check-item">
                  <input
                    name={item.slug}
                    type="checkbox"
                    checked={itemSelected}
                    onChange={() => {
                      setSelectedOptions([...selectedOptions, item]);
                    }}
                  />
                  <label htmlFor={item.slug} className={itemSelected ? 'selected' : ''}>
                    {item.name} ({item.publishedRecipesCount})
                  </label>
                </div>
              </li>
            );
          });

          const icon = (open: boolean) => {
            if (isMobile) {
              return open ? <MinusIcon /> : <PlusIcon />;
            } else {
              return isOpen ? <UpArrowIcon /> : <DownArrowIcon />;
            }
          };

          return (
            <div className="filter-button-container">
              <FilterButton
                onClick={() => {
                  if (isOpen) {
                    setOpenFilterId(undefined);
                  } else {
                    setOpenFilterId(filter.id);
                  }
                }}
                label={filter.label}
                icon={icon(isOpen)}
              />
              {isOpen ? (
                <ul className="drop-down" {...getMenuProps()}>
                  {lis}
                </ul>
              ) : null}
            </div>
          );
        }}
      </Downshift>
      <style jsx>{`
        div.filter-button-container {
          border-bottom: 2px solid #dadada;
        }
        .drop-down {
          max-height: 500px;
          max-width: 320px;
          min-width: 288px;
          font: 300 14px/1.43 Montserrat, Helvetica, Arial, sans-serif;
          background-color: #fff;
          padding: 0.5rem 1rem;
          overflow-x: hidden;
          overflow-y: auto;
          margin-top: 5px;
          white-space: normal;
        }

        .drop-down li {
          padding: 0;
          margin: 0;
          list-style: none;
          margin-right: 0.375rem;
          margin-top: 0.5rem;
        }

        .drop-down .check-item {
          position: relative;
          margin: 0;
          padding: 0;
          display: inline;
        }

        .drop-down .check-item input {
          opacity: 0;
          vertical-align: middle;
          width: 24px;
          height: 24px;
          padding: 0;
          margin: 0;
        }

        .drop-down .check-item label {
          cursor: pointer;
          vertical-align: text-top;
          margin-left: 10px;
          min-height: 24px;
        }

        .drop-down .check-item label::before {
          position: absolute;
          left: 0;
          top: calc(50% - 14px);
          content: '';
          height: 24px;
          width: 24px;
          border: 2px solid #595959;
          border-radius: 2px;
          background: #fff;
        }

        .drop-down .check-item label.selected::after {
          content: '';
          color: #595959;
          position: absolute;
          left: 6px;
          top: calc(50% - 6px);
          display: inline-block;
          height: 6px;
          width: 14px;
          border-left: 2px solid;
          border-bottom: 2px solid;
          transform: rotate(-45deg);
        }

        @media ${mediaQuery.Medium} {
          div.filter-button-container {
            border-bottom: none;
          }
          .drop-down {
            position: absolute;
            box-shadow: 12px 13px 22px 0 rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            border: 2px solid #d6d6d6;
          }
        }
      `}</style>
    </>
  );
};
