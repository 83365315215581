import React from 'react';
import { XIcon } from './Icons';
import { mediaQuery } from '../util/ui';

export const SelectedOption: React.FunctionComponent<{
  label: string;
  onUnselectOption: () => void;
}> = ({ label, onUnselectOption }) => {
  return (
    <>
      <div>
        <span className="label">{label}</span>
        <button className="icon" aria-label="remove" onClick={onUnselectOption}>
          <XIcon color="#fff" />
        </button>
      </div>
      <style jsx>{`
        div {
          font-weight: bold;
          display: flex;
          align-content: center;
          font-family: var(--font-family-primary);
          line-height: normal;
          display: flex;
          align-content: baseline;
          padding: 15px 12px 15px 18px;
          background-color: #595959;
          color: #fff;
          border: none;
        }
        .label {
          cursor: default;
        }
        .icon {
          cursor: pointer;
          border: none;
          background-color: #595959;
          color: #fff;
          margin-left: 6px;
        }
        button:active {
          background-color: #2b2a2a;
        }

        @media ${mediaQuery.Medium} {
          button {
            border-radius: 4px;
            justify-content: center;
          }
        }
      `}</style>
    </>
  );
};
