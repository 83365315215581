import { FilterModal } from '../filter-modal';
import React, { useState, useMemo, useEffect } from 'react';
import { CONTENT_MAX_WIDTH, mediaQuery, minimumSize } from '../../util/ui';
import { PartialRecipe, RecipeOptionWithFilter } from '../../types/api/entities/recipe';
import { RecipeFilter, RecipeFilterOption } from '../../types/api/entities/recipe-filter';
import { useTranslation, withTranslation } from 'next-i18next';
import { WithTranslation as WithTranslationType } from 'next-i18next';
import { FilterSelect } from '../filter-select';
import { SelectedOption } from '../selected-option';
import { GetRecipesActionOptions } from '../../store/actions/api/get-recipes';

import { BasicButton } from '../buttons';
import { useMediaQuery } from 'react-responsive';
import { encodeFiltersUrlPath } from '../../util/url';
import { connect } from 'react-redux';
import { StoreState } from '../../store/state';
import { RecipesList } from '@commerceowl/components';
import { LinkedRecipeCard } from './card';

export interface RecipeListProps {
  deactivateRatings: boolean;
  recipes: PartialRecipe[];
  filters: RecipeFilter[];
  onLoadMoreClick: () => void;
  showMoreEnabled: boolean;
  activeFilter?: number;
  getRecipes?: (options: GetRecipesActionOptions) => void;
  hideFilters?: boolean;
  initialFilters?: RecipeOptionWithFilter[];
}

interface StateProps {
  filterIdsByOptionIds: Record<number, number>;
  filtersById: Record<number, RecipeFilter>;
}

const slugsToOptions = (
  items: RecipeOptionWithFilter[],
  filters: RecipeFilter[],
): RecipeFilterOption[] => {
  const result: RecipeFilterOption[] = [];

  items.forEach((item) => {
    filters.forEach((filter) => {
      if (filter.label === item.filterLabel) {
        filter.options.forEach((option) => {
          if (option.slug === item.optionSlug) {
            result.push(option);
          }
        });
      }
    });
  });

  return result;
};

const _RecipeList: React.FunctionComponent<RecipeListProps & StateProps & WithTranslationType> = ({
  recipes,
  filters,
  onLoadMoreClick,
  showMoreEnabled,
  getRecipes,
  deactivateRatings,
  hideFilters,
  initialFilters,
  filterIdsByOptionIds,
  filtersById,
}) => {
  const { t } = useTranslation();
  const [openFilterId, setOpenFilterId] = useState<number>();
  const [selectedOptions, setSelectedOptions] = useState<RecipeFilterOption[]>([]);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: `${minimumSize.Medium}px` });

  const onSelectedOptionsChange = (options: RecipeFilterOption[]) => {
    setSelectedOptions(options);
    updateRecipes(getRecipes, options);

    if (options.length > 0) {
      const filters = options
        .map((option) => {
          const filterId = filterIdsByOptionIds[option.id];

          if (filterId) {
            const filter = filtersById[filterId];

            if (filter) {
              return { filterLabel: filter.label, optionSlug: option.slug };
            }
          }

          return undefined;
        })
        .filter((item) => item) as RecipeOptionWithFilter[];

      history.pushState({}, document.title, `/recipes/${encodeFiltersUrlPath(filters)}`);
    } else {
      history.pushState({}, document.title, '/');
    }
  };

  useEffect(() => {
    if (initialFilters) {
      const options = slugsToOptions(initialFilters, filters);

      if (options.length > 0) {
        setSelectedOptions(options);
      }
    }
  }, [initialFilters, filters]);

  const filtersWithRecipes = useMemo(
    () =>
      filters
        .map((filter) => ({
          ...filter,
          options: filter.options.filter((option) => option.publishedRecipesCount > 0),
        }))
        .filter((filter) => filter.options.length > 0),
    [filters],
  );

  return (
    <div>
      {hideFilters ? null : (
        <div className="controls-container">
          <div className="controls">
            <h1>{t('AllRecipes')}</h1>
            {isMobile ? (
              <BasicButton
                label="Filter"
                onClick={() => {
                  setFilterModalOpen(true);
                }}
              />
            ) : (
              <div className="filters">
                {filtersWithRecipes.map((filter, index) => (
                  <span key={index}>
                    <FilterSelect
                      filter={filter}
                      setSelectedOptions={onSelectedOptionsChange}
                      isOpen={filter.id === openFilterId}
                      setOpenFilterId={setOpenFilterId}
                      selectedOptions={selectedOptions}
                    />
                  </span>
                ))}
              </div>
            )}
            <FilterModal
              filters={filters}
              filterModalOpen={filterModalOpen}
              setSelectedOptions={onSelectedOptionsChange}
              setFilterModalOpen={setFilterModalOpen}
              selectedOptions={selectedOptions}
            />

            <div className="selected-options">
              {selectedOptions.map((option) => {
                return (
                  <span key={option.id}>
                    <SelectedOption
                      label={option.name}
                      onUnselectOption={() => {
                        const remainingOptions = selectedOptions.filter(
                          (selectedOption) => option.id !== selectedOption.id,
                        );

                        // updateRecipes(getRecipes, remainingOptions);

                        onSelectedOptionsChange(remainingOptions);
                      }}
                    />
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="recipes-list-wrapper">
        <RecipesList
          showLoadMoreButton={showMoreEnabled}
          recipes={recipes}
          renderItem={(recipe) => (
            <LinkedRecipeCard recipe={recipe} showRating={!deactivateRatings} />
          )}
          onLoadMoreClick={onLoadMoreClick}
          emptyRecipesListMessage={t('EmptyRecipesList')}
          loadMoreMessage={t('LoadMore')}
        />
      </div>
      <style jsx>{`
        a {
          text-decoration: none;
          color: inherit;
        }

        .controls-container {
          display: flex;
          width: 100%;
          justify-content: center;
        }
        .controls {
          max-width: ${CONTENT_MAX_WIDTH};
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 0 10px;
        }
        .selected-options,
        .filters {
          display: flex;
        }

        .controls h1 {
          font-family: var(--font-family-secondary);
        }

        .selected-options span:not(:first-child),
        .filters span:not(:first-child) {
          margin-left: 15px;
        }

        .selected-options {
          margin-top: 24px;
        }

        .recipes-list-wrapper {
          padding-bottom: 33px;
          margin-top: 24px;
        }

        .recipes-container {
          display: flex;
          width: 100%;
          box-sizing: border-box;
          flex-wrap: wrap;
          justify-content: space-around;
        }

        .recipe-card {
          width: 100%;
        }
        .recipe-card:first-child {
          margin-top: 0;
        }

        .recipe-card {
          margin-top: 30px;
        }

        .recipes-load-more-container {
          margin-top: 19px;
          text-align: center;
          width: 100%;
        }

        .recipes-load-more-container :global(.load-more-button) {
          max-width: 301px;
        }

        @media ${mediaQuery.Medium} {
          .recipes-list-wrapper {
            padding-bottom: 84px;
          }

          .recipe-card {
            width: 45%;
          }

          .recipe-card:nth-child(2) {
            /* reset blank margin-top rule from smaller viewport */
            margin-top: 0;
          }

          .recipe-card:nth-child(n + 3) {
            margin-top: 30px;
          }

          .recipes-load-more-container {
            margin-top: 78px;
          }
        }

        @media ${mediaQuery.Large} {
          .recipe-card {
            width: 30%;
          }

          .recipe-card:nth-child(2) {
            /* reset blank margin-top rule from smaller viewport */
            margin-top: 0;
          }

          .recipe-card:nth-child(3) {
            /* reset blank margin-top rule from smaller viewport */
            margin-top: 0;
          }

          .recipe-card:nth-child(n + 4) {
            margin-top: 30px;
          }
        }

        @media ${mediaQuery.ExtraLarge} {
          .recipes-list-wrapper {
            padding-bottom: 100px;
          }

          .recipe-card {
            width: 290px;
          }

          .recipe-card:nth-child(4) {
            /* reset blank margin-top rule from smaller viewport */
            margin-top: 0;
          }

          .recipe-card:nth-child(n + 5) {
            margin-top: 30px;
          }

          .recipes-load-more-container {
            margin-top: 100px;
          }

          .recipes-load-more-container :global(.load-more-button) {
            max-width: 210px;
          }
        }
      `}</style>
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  filterIdsByOptionIds: state.entities.filter.idByOptionId,
  filtersById: state.entities.filter.byId,
});

export const RecipeList = connect(mapStateToProps)(withTranslation()(_RecipeList));

function updateRecipes(
  getRecipes: ((options: GetRecipesActionOptions) => void) | undefined,
  options: RecipeFilterOption[],
) {
  if (getRecipes) {
    getRecipes({
      options: {
        optionId: options.map((o) => o.id),
      },
    });
  }
}
