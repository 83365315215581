import { RecipeOptionWithFilter } from '../types/api/entities/recipe';

export const encodeFiltersUrlPath = (items: RecipeOptionWithFilter[]): string => {
  return encodeURIComponent(
    items
      .flatMap(
        ({ filterLabel, optionSlug }) =>
          `${filterLabel.replace(/_/g, '%5f').replace(/-/g, '%2d')}-${optionSlug
            .replace(/_/g, '%5f')
            .replace(/-/g, '%2d')}`,
      )
      .join('_'),
  );
};

export const decodeFiltersUrlPath = (path: string): RecipeOptionWithFilter[] => {
  const segments = path.split('_');

  const result: RecipeOptionWithFilter[] = [];

  segments.forEach((segment) => {
    const [filterLabel, optionSlug] = segment.split('-').map(decodeURIComponent);

    if (filterLabel && optionSlug) {
      result.push({ filterLabel, optionSlug });
    }
  });

  return result;
};
