import React from 'react';
import { mediaQuery } from '../util/ui';

export function FilterButton({
  icon,
  onClick,
  label,
}: {
  icon?: React.ReactElement;
  onClick: () => void;
  label: string;
}) {
  return (
    <button onClick={onClick}>
      <span className="label">{label}</span>
      {icon ? <span className="icon">{icon}</span> : null}
      <style jsx>{`
        button {
          cursor: pointer;
          font-weight: bold;

          display: flex;

          justify-content: space-between;
          align-items: center;
          font-family: var(--font-family-primary);
          line-height: normal;
          display: flex;
          align-content: baseline;
          padding: 15px 18px;
          background-color: #fff;
          border: none;
          width: 100%;
        }
        .icon {
          margin-left: 11px;
        }
        button:active {
          background-color: #dadada;
        }

        @media ${mediaQuery.Medium} {
          button {
            width: unset;
            border-radius: 4px;
            border: 2px solid #dbdbdb;
            width: 100%;
            justify-content: center;
          }
        }
      `}</style>
    </button>
  );
}
export function BasicButton({
  icon,
  onClick,
  label,
}: {
  icon?: React.ReactElement;
  onClick: () => void;
  label: string;
}) {
  return (
    <button onClick={onClick}>
      <span className="label">{label}</span>
      {icon ? <span className="icon">{icon}</span> : null}
      <style jsx>{`
        button {
          cursor: pointer;
          font-weight: bold;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          font-family: var(--font-family-primary);
          line-height: normal;
          display: flex;
          align-content: baseline;
          padding: 15px 18px;
          background-color: #fff;
          border: 2px solid #dbdbdb;
        }
        .icon {
          margin-left: 11px;
        }
        button:active {
          background-color: #dadada;
        }
      `}</style>
    </button>
  );
}
