import { GetRecipesOptions } from '../../../types/api/entities/recipe';

export interface GetRecipesActionOptions {
  options?: GetRecipesOptions;
  loadMore?: boolean;

  previousIds?: number[];
}

export interface GetRecipesAction {
  type: 'API/GET_RECIPES';
  options: GetRecipesActionOptions;
}

export function getRecipes(options: GetRecipesActionOptions): GetRecipesAction {
  return {
    type: 'API/GET_RECIPES',
    options,
  };
}
