import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Modal, { setAppElement } from 'react-modal';
import { XIcon } from './Icons';
import { RecipeFilter, RecipeFilterOption } from '../types/api/entities/recipe-filter';
import { FilterSelect } from './filter-select';
import * as noScroll from 'no-scroll';
import { BasicButton } from './buttons';
import { useTranslation } from 'next-i18next';

interface Props {
  filterModalOpen: boolean;
  setFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedOptions: (selectedOptions: RecipeFilterOption[]) => void;
  selectedOptions: RecipeFilterOption[];
  filters: RecipeFilter[];
}

export const FilterModal: React.FunctionComponent<Props> = ({
  filterModalOpen,
  setFilterModalOpen,
  setSelectedOptions,
  filters,
  selectedOptions,
}) => {
  {
    /** ariaHideApp={false} isn't recommended but adding it since couldn't find a way to get next.js's app element.
     * Also there's this: https://github.com/reactjs/react-modal/issues/133 */
  }

  const [openFilterIds, setOpenFilterIds] = useState<number[]>([]);
  const [tempSelectedOptions, setTempSelectedOptions] = useState<RecipeFilterOption[]>([]);

  setAppElement('#__next'); // prevents a warning from happening. This could be brittle if next changes the app id

  useEffect(() => {
    /* To prevent main app from scrolling while we have modal open, we use this noscroll solution.
    See: https://github.com/reactjs/react-modal/issues/191 
    */
    if (filterModalOpen) {
      noScroll.on();
    }

    return () => noScroll.off();
  });

  const { t } = useTranslation();

  useEffect(() => {
    setTempSelectedOptions(selectedOptions);
  }, [selectedOptions]); // allows us to update the internal (tempSelectedOptions) when options are removed outside the modal

  return (
    <Modal
      isOpen={filterModalOpen}
      style={{
        content: {
          inset: '0px',
          padding: 0,
        },
      }}
    >
      <div className="modal-container">
        <header>
          <h1>{t('Filters')}</h1>
          <button
            className="close-button"
            onClick={() => {
              setFilterModalOpen(false);
            }}
          >
            <XIcon color="#000" height={15} width={15} />
          </button>
        </header>
        <div className="filters-container">
          {filters.map((filter) => {
            return (
              <FilterSelect
                key={filter.id}
                filter={filter}
                isOpen={openFilterIds.includes(filter.id)}
                setOpenFilterId={(toggledFilter: number | undefined) => {
                  const newOpenFilters = toggledFilter
                    ? [...openFilterIds, toggledFilter]
                    : openFilterIds.filter((id) => id !== toggledFilter);
                  setOpenFilterIds(newOpenFilters);
                }}
                setSelectedOptions={setTempSelectedOptions}
                selectedOptions={tempSelectedOptions.filter((o) =>
                  filter.options.some((option) => o.id === option.id),
                )}
              />
            );
          })}
        </div>
        <footer>
          <BasicButton
            onClick={() => {
              setSelectedOptions(tempSelectedOptions);
              setFilterModalOpen(false);
            }}
            label="View Results"
          />
        </footer>
      </div>
      <style jsx>{`
        .modal-container {
          min-height: 100%;
          align-items: stretch;

          display: flex;
          flex-direction: column;
        }
        header {
          padding: 20px 20px 10px 20px;
          border-bottom: 2px solid #d6d6d6;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-shrink: 0;
        }

        footer {
          flex-shrink: 0;
          background-color: white;
          height: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h1 {
          margin: 0;
        }
        .close-button {
          cursor: pointer;
          font-weight: bold;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          font-family: var(--font-family-primary);
          line-height: normal;
          display: flex;
          align-content: baseline;
          background-color: #fff;
          border: none;
        }

        .filters-container {
          margin: 0 20px;
          flex-grow: 1;
          min-height: 100%;
          height: 100px;
          overflow-y: auto;
        }
      `}</style>
    </Modal>
  );
};
